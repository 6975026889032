/** @jsx jsx */
import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {StaticImage} from 'gatsby-plugin-image';

import {jsx, keyframes} from '@emotion/react';
import styled from '@emotion/styled';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import {ContrastContext} from '../../helpers/context';
import SEO from '../../components/common/SEO';
import ProductHero from '../../components/common/ProductHero';
import ProductPageContainer from '../../components/common/ProductPageContainer';
import {breakpoints, colors, ContentContainer, fonts, fontWeights} from '../../styles/theme';
import {H1, H5, H6, Hero} from '../../components/common/Typography';
import {BaseButtonStyles, CTA, LinkAsPrimaryButton} from '../../components/common/Clickables';
import AnimatedBackground from '../../components/common/AnimatedBackground';
import PaymentsFailBackground from '../../images/product/dynamic-routing/payments-fail-background.png';


/*
 * Constants
 */
/* eslint-disable-next-line i18next/no-literal-string */
const videoUrl = null;

// Limits of the restricted area to avoid to place items on the animation (probable position of the text to avoid)
// Percentages. Zeros on the top left corner.
const coordinatesRestrictLimits = {
    minX: 0,
    maxX: 100,
    minY: 30,
    maxY: 60,
};


/*
 * Helper methods
 */
/**
 * Generate a random positioning skew according to a possible variance.
 *
 * @example
 * // returns anything between -10 and 10
 * randomSkew(10)
 * @example
 * // returns anything between -5 and 5
 * randomSkew(5)
 *
 * @param {number} variance - The allowed variance
 * @returns {number}
 */
function randomSkew(variance) {
    return Math.random() * (2 * variance) - variance;
}

/**
 * Generate the positions array for placing the floating buttons.
 *
 * @param {number} minimumNumberOfElements - The minimum number of buttons to place on the screen
 * @returns {[{...}]}
 */
const generatePositionsArray = minimumNumberOfElements => {
    const positionsArray = [];

    // Calculate the amount of rows and columns necessary to display all the elements in a grid. It contains AT LEAST
    // `<minimumNumberOfElements>` elements, but might contain more to make a squared grid (ex:
    // minimumNumberOfElements=4  will generate a 2x2 grid with 4 elements, but minimumNumberOfElements=5 will generate
    // a 3x3 grid with 9 elements).
    const elementsPerRowOrCol = Math.ceil(Math.sqrt(minimumNumberOfElements));

    // Skew all the elements' positions to achieve an even spread on the screen (the calculated position places them
    // at the top left corner of their "space", so we apply this skew to center them). This was achieved by
    // experimenting, there is no specific formula.
    const positioningSkew = 15 / elementsPerRowOrCol;

    // Loop through rows
    for (let row = 0; row <= elementsPerRowOrCol - 1; row++) {
        // Loop through columns
        for (let column = 0; column <= elementsPerRowOrCol - 1; column++) {
            // Generate a point with random coordinates
            const coordinates = {
                x: Math.round((column * 100) / elementsPerRowOrCol) + positioningSkew + randomSkew(4),
                y: Math.round((row * 100) / elementsPerRowOrCol) + positioningSkew + randomSkew(4),
            };

            // Try to avoid the restricted area
            if (coordinates.x >= coordinatesRestrictLimits.minX && coordinates.x <= coordinatesRestrictLimits.maxX
                && coordinates.y >= coordinatesRestrictLimits.minY && coordinates.y <= coordinatesRestrictLimits.maxY) {
                coordinates.y = Math.random() - 0.5 > 0.25  // Slight tendency to place above the restricted area
                    ? coordinatesRestrictLimits.maxY + 2 + randomSkew(2)  // Place below the restricted area
                    : coordinatesRestrictLimits.minY - 2 - randomSkew(2);  // Place above the restricted area
            }

            positionsArray.push(coordinates);
        }
    }

    // Return
    return positionsArray;
};

/**
 * Get amount of random texts from the list.
 *
 * If n is less than or equal to the list length, no duplicates are returned.
 *
 * @param textsList - The original ordered list of texts to get from.
 * @param n - The number of texts to get from the list.
 * @returns {[string]}
 */
const getRandomTexts = (textsList, n) => {
    const selectedTexts = [];
    // Randomize the order of the texts
    const randomTexts = textsList.sort(() => Math.random() - 0.5);

    // Iterate through the randomized list. If the number of texts we want is more than
    // the length of the list, we cycle again
    for (let i = 0; i < n; i++) {
        selectedTexts.push(randomTexts[i % randomTexts.length]);
    }

    return selectedTexts;
};


/*
 * Private Elements
 */
const AcceptanceRatesContainer = styled.div`
    position: relative;
    color: ${colors.white};

    .animated-background {
        padding-top: 70px;
        padding-bottom: 70px;

        ${ContentContainer} {
            ${Hero} {
                margin-bottom: 25px;
            }

            ${H5} {
                font-weight: ${fontWeights.regular};
                margin-bottom: 50px;
            }

            .graphic {
                margin-top: 60px;
            }
        }
    }

    @media (min-width: ${breakpoints.md}) {
        .animated-background {
            padding-top: 150px;
            padding-bottom: 150px;

            ${ContentContainer} {
                .graphic {
                    margin-top: 0;
                }
            }
        }
    }
`;

const PaymentsFailContainer = styled.div`
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    text-align: center;

    .background {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: -1;
        overflow: hidden;
        background: ${colors.white} url(${PaymentsFailBackground}) no-repeat scroll bottom right;
        background-size: cover;
    }

    ${ContentContainer} {
        max-width: 500px;

        ${Hero} {
            margin-bottom: 25px;

            @media (max-width: ${breakpoints.sm}) {
                font-size: 20px;
                line-height: 30px;
                margin-bottom: 15px;
            }
        }
    }

    @media (min-width: ${breakpoints.lg}) {
        ${ContentContainer} {
            max-width: 800px;
        }
    }
`;

const ReduceProcessingRatesContainer = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;

    ${ContentContainer} {
        margin-top: 35px;
        margin-bottom: 70px;

        ${H1} {
            margin-bottom: 25px;
        }

        ${H6} {
            font-weight: ${fontWeights.regular};
            margin-bottom: 50px;
        }
    }

    @media (min-width: ${breakpoints.md}) {
        position: relative;
        padding-top: 0;
        padding-bottom: 70px;

        ${ContentContainer} {
            position: relative;
            margin-top: 80px;
        }

        .pipes-1 {
            position: absolute;
            top: -80px;
            left: 0;
            width: 100%;

            &>div {
                max-width: 1100px;
            }
        }
    }
`;

// Floating Button
const StyledFloatingButton = styled.button`
    ${BaseButtonStyles};
    position: absolute;
    font-family: ${fonts.monospace};
    font-weight: ${fontWeights.regular};
    background-color: #EEEEEE;
    padding: 10px 17px;
    height: auto;
    box-shadow: -5px 9px 12px 0 rgba(81, 81, 81, 0.17);
    cursor: default !important;
    will-change: transform, filter;

    @media (max-width: ${breakpoints.sm}) {
        min-height: 25px;
        font-size: 12px;
        line-height: 12px;
        padding: 8px 10px;
    }
`;

/**
 * Display a floating button
 * This element receives a position to be placed (it is positioned as absolute) and it will be animated to "float" to
 * top and bottom, while blurring the further away it is from the top. The starting position, direction and animation
 * duration are randomized so that several make a randomized animation.
 *
 * @param {string} text - the text to display in the button
 * @param {string} x - X position (in css "top" units)
 * @param {string} y - Y position (in css "left" units)
 */
const FloatingButton = ({text, x, y}) => {
    const maxDepth = 1 / 5;
    const minDepth = 1;

    // Get a random animation order so that we have elements starting out shrinking and others growing
    const order = Math.random() < 0.5 ? [maxDepth, minDepth] : [minDepth, maxDepth];

    // Randomly generate the animation duration to have elements at different speeds (can go from 30 to 40s)
    const duration = Math.floor(Math.random() * 10) + 10;

    // Randomly generate the start delay of the animations (can go from -10 to 1). A negative delay acts like the
    // animation had already started by that amount of time when it's initially rendered.
    const delay = Math.floor(Math.random() * 11) - 9;

    // Calculate the start and end positions based on the random order assignment before
    const startScale = order[0];
    const endScale = order[1];

    // Blur is inversely proportional to how far the object is, so we use the "end" scale to calculate the initial blur,
    // and vice versa. Blur can take values from 0 (closest to the screen) to 8 (farthest from the screen).
    const startBlur = (endScale * 5 - 1) * 2;
    const endBlur = (startScale * 5 - 1) * 2;

    const animation = keyframes`
        0% {
            transform: scale(${startScale});
            filter: blur(${startBlur}px);
        }
        50% {
            transform: scale(${endScale});
            filter: blur(${endBlur}px);
        }
        100% {
            transform: scale(${startScale});
            filter: blur(${startBlur}px);
        }
    `;

    const buttonCss = {
        top: y,
        left: x,
        animation: `${animation} ${duration}s ease-in-out infinite`,
        animationDelay: `${delay}s`,
    };

    return (
        <StyledFloatingButton css={buttonCss} className="animation">{text}</StyledFloatingButton>
    );
};

FloatingButton.propTypes = {
    text: PropTypes.string.isRequired,
    x: PropTypes.string.isRequired,
    y: PropTypes.string.isRequired,
};


/*
 * Public Elements
 */
const DynamicRoutingPage = () => {
    const setContrast = useContext(ContrastContext);
    setContrast(false);
    const {t} = useTranslation();

    const section = t('Dynamic Routing');
    // eslint-disable-next-line i18next/no-literal-string
    const sectionStr = 'Dynamic Routing';
    const hero = t('Optimize your payments performance.');
    const description = t('Use custom rules to route transactions between payment providers in real-time, maximizing '
        + 'acceptance rates, and reducing processing and FX rates.');
    const image = (
        <StaticImage src="../../images/product/dynamic-routing/logo.png" placeholder="none" loading="eager" alt="" />
    );
    const mobileImage = (
        <StaticImage
            src="../../images/product/dynamic-routing/logo-mobile.png"
            placeholder="none"
            loading="eager"
            alt=""
        />
    );

    const floatingButtonTexts = [
        t('Timed out'),
        t('Insecure transaction'),
        t('Card declined'),
        t('Canceled by user'),
        t('Invalid payment data'),
        t('Invalid card number'),
        t('Expired credit card'),
        t('Validation error'),
        t('Payment verification failed'),
        t('Payment not processed'),
        t('Payment failed'),
        t('Payment method declined'),
        t('Failed authentication by issuer'),
        t('Charge failed'),
    ];

    const MIN_NUM_FLOATING_BUTTONS = 8;
    const positions = generatePositionsArray(MIN_NUM_FLOATING_BUTTONS);
    const selectedTexts = getRandomTexts(floatingButtonTexts, positions.length);
    const floatingButtons = positions.map((coordinates, index) => {
        const text = selectedTexts[index];
        return (
            <FloatingButton
                text={text}
                x={`${coordinates.x}%`}
                y={`${coordinates.y}%`}
                key={`${text} ${coordinates.x} ${coordinates.y}`}
            />
        );
    });

    return (
        <>
            <SEO
                title={section}
                ogTitle={t('Maximize Payments Success with Dynamic Routing - Switch')}
                description={t('Smart payment routing. Distribute transactions to the best payment provider. Save on '
                    + 'transaction fees and optimize payment acceptance rates with routing rules.')}
                image="/website/metatags/dynamic-routing.png"
            />

            <ProductHero
                section={section}
                sectionStr={sectionStr}
                hero={hero}
                description={description}
                image={image}
                mobileImage={mobileImage}
                videoUrl={videoUrl}
            />

            <ProductPageContainer>
                <AcceptanceRatesContainer className="product-shadow">
                    <AnimatedBackground>
                        <ContentContainer>
                            <div className="row no-gutters">
                                <div className="col-12 col-md-4 offset-md-1">
                                    <Hero><Trans>Increase your acceptance rates.</Trans></Hero>

                                    <H5>
                                        <Trans>
                                            E-commerce transactions can get declined for all sorts of reasons and lost
                                            revenue that can add up at scale. To fix that, we retry authorizations in
                                            real-time across multiple payment providers, maximizing the acceptance
                                            rates.
                                        </Trans>
                                    </H5>

                                    <CTA to="/learn/6079a35a5c98ce001052b413">
                                        <Trans>Read more about Acceptance Rates</Trans>
                                    </CTA>
                                </div>
                                <div className="col-12 col-md-5 offset-md-1 graphic">
                                    <StaticImage
                                        src="../../images/product/dynamic-routing/acceptance-rates.png"
                                        placeholder="tracedSVG"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </ContentContainer>
                    </AnimatedBackground>
                </AcceptanceRatesContainer>

                <PaymentsFailContainer>
                    <div className="background">
                        <div className="buttons">{floatingButtons}</div>
                    </div>

                    <ContentContainer>
                        <Hero><Trans>Why do payments fail and how can you fix it?</Trans></Hero>
                        <LinkAsPrimaryButton to="/learn/6079926e5c98ce001052b3f2">
                            <Trans>Learn more</Trans>
                        </LinkAsPrimaryButton>
                    </ContentContainer>
                </PaymentsFailContainer>

                <ReduceProcessingRatesContainer>
                    <div className="row no-gutters pipes-1">
                        <div className="col-12 col-md-7">
                            <StaticImage
                                src="../../images/product/dynamic-routing/pipe-1.png"
                                placeholder="tracedSVG"
                                alt=""
                            />
                        </div>
                    </div>
                    <ContentContainer>
                        <div className="row no-gutters">
                            <div className="col-12 col-md-5 offset-md-6">
                                <H1><Trans>Reduce your processing rates.</Trans></H1>
                            </div>
                            <div className="col-12 col-md-5 offset-md-6">
                                <H6>
                                    <Trans>
                                        A rise in cross-border transactions is pushing merchants&apos; processing costs
                                        up. Switch allows for the creation of rules that take into account processing
                                        variables to choose the optimum transaction route.
                                    </Trans>
                                </H6>
                            </div>
                            <div className="col-12 col-md-5 offset-md-6">
                                <CTA
                                    to="https://switchpayments.com/docs/dynamic-routing#routing-rules"
                                    target="_blank"
                                    colored
                                >
                                    <Trans>Learn more about routing rules</Trans>
                                </CTA>
                            </div>
                        </div>
                    </ContentContainer>
                    <div className="row no-gutters">
                        <div className="col-4 offset-8 col-md-3 offset-md-9 col-lg-2 offset-lg-10">
                            <StaticImage
                                src="../../images/product/dynamic-routing/pipe-2.png"
                                placeholder="tracedSVG"
                                alt=""
                            />
                        </div>
                    </div>
                </ReduceProcessingRatesContainer>
            </ProductPageContainer>
        </>
    );
};


/*
 * Exports
 */
export default DynamicRoutingPage;
